import TagManager from 'react-gtm-module';
import { GenerateLeadData, SelectContentData, ViewPromotionData, SelectPromotionData } from '@/interfaces/ecommerce-tracking';
import { BookingSearchData } from '@/interfaces/booker-tracking';
import { saveOrUpdatePromoDataToStorage } from '../promotions';

export const trackEvent = (eventName: string, eventData?: GenerateLeadData | ViewPromotionData | SelectPromotionData | SelectContentData | BookingSearchData) => {
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      ...eventData
    }
  });
};

export const trackViewPromotion = (data: ViewPromotionData) => {
  trackEvent("ir.view_promotion", data);
};

export const trackBookingSearch = (data: BookingSearchData) => {
  trackEvent("ir.booking_start", data);
};

export const trackSelectPromotion = (data: SelectPromotionData) => {
  trackEvent("ir.select_promotion", data);
  savePromoDataToCookie(data);
};

const savePromoDataToCookie = (data: SelectPromotionData) => {
  saveOrUpdatePromoDataToStorage(data);
};