function calculateGCD(a: number, b: number): number {
  while (b !== 0) {
    let temp = b;
    b = a % b;
    a = temp;
  }
  return a;
}

export function calculateAspectRatio(w: number, h: number): string {
  const gcd = calculateGCD(w, h);
  const ratioAncho = w / gcd;
  const ratioAlto = h / gcd;

  return `${ratioAncho}/${ratioAlto}`;
}