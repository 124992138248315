import type { AppProps } from 'next/app'
import { ContentProvider, BookerProvider } from '@/context';
import { appWithTranslation } from 'next-i18next'
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { initializeErrorTracking } from '@/utils';
import { ttTravels, gtSectraFine, cooperHewitt, rageItalic, microsoft } from '@/utils/shared/app/getLocalFonts'
import { electrolize, notoSansJP, roboto } from '@/utils/shared/app/getFonts';
import '@/styles/globals.scss';

const App = ({ Component, pageProps }: AppProps) => {

  useEffect(() => {
    TagManager.initialize({ 
      gtmId: process.env.KILLA_GTM_ID as string,

      // Verify if the environment is production
      ...(process.env.NODE_ENV === 'production' ? {} : {
        preview: process.env.KILLA_GTM_ENV as string ?? null,
        auth: process.env.KILLA_GTM_AUTH as string ?? null,
      })
    });
    initializeErrorTracking({ trackUnhandledRejections: true });
  }, []);

  return (
    <>
      <style jsx global>{`
        :root {
          /* ... */
          --noto-sans-jp: ${notoSansJP.style.fontFamily};
          --roboto-font: ${roboto.style.fontFamily};
          --tt-travels-font: ${ttTravels.style.fontFamily};
          --rage-italic-font: ${rageItalic.style.fontFamily};
          --gt-sectra-fine-font: ${gtSectraFine.style.fontFamily};
          --cooper-font: ${cooperHewitt.style.fontFamily};
          --electrolize-font: ${electrolize.style.fontFamily};
          --ms-font: ${microsoft.style.fontFamily};
        }
      `}</style>
      <ContentProvider>
        <BookerProvider>
        <Component {...pageProps} />
        </BookerProvider>
      </ContentProvider>
    </>
  )
}

export default appWithTranslation(App);
