import React from "react";
import { BreakpointKey, breakpoints, getContentfulImage, calculateAspectRatio } from "@/utils";
import { Asset } from "contentful";
import Image from "next/image";

export const renderImages = (classes: string, name: string, images: Asset[], imageFormat: string, imageCompression: number, withCaption?: boolean, isLazy?: boolean) => {

  if (!images || images.length === 0) {
    return <></>;
  }

  // We sort the images array in order to get desktop image first, then tablet and mobile at last.
  const orderImages = ['desktop', 'tablet', 'mobile'];
  const sortedImages = images.filter((i) => orderImages.indexOf(i.fields.title) >= 0).sort((a, b) => orderImages.indexOf(a.fields.title) - orderImages.indexOf(b.fields.title))

  if (sortedImages.length === 0) {
    return <></>;
  }

  return withCaption ? (
    <figure className={classes}>
      <picture>
        {sortedImages.map((image) => (
          getContentfulImage(image, { q: imageCompression || 75, fm: imageFormat || 'WEBP' }) &&
          <source
            key={image.sys.id}
            media={breakpoints[image.fields.title as BreakpointKey]}
            srcSet={getContentfulImage(image, { q: imageCompression || 75, fm: imageFormat || 'WEBP' }) ?? ''}
            type={`image/${imageFormat || 'WEBP'}`}
          />
        ))}
        <Image
          loading={isLazy ? 'lazy' : 'eager'}
          width={sortedImages[0].fields.file.details.image?.width}
          height={sortedImages[0].fields.file.details.image?.height}
          src={`${getContentfulImage(images[0], { q: imageCompression || 75, fm: imageFormat || 'WEBP' }) ?? ''}`}
          alt={sortedImages[0].fields.description ?? name ?? 'Incarail'}
        />
      </picture>
      <figcaption>{images[0].fields.description}</figcaption>
    </figure>
  ) : (
    <picture className={classes}>
      {sortedImages.map((image) => (
        getContentfulImage(image, { q: imageCompression || 75, fm: imageFormat || 'WEBP' }) &&
        <source
          key={image.sys.id}
          media={breakpoints[image.fields.title as BreakpointKey]}
          srcSet={getContentfulImage(image, { q: imageCompression || 75, fm: imageFormat || 'WEBP' }) ?? ''}
          type={`image/${imageFormat || 'WEBP'}`}
        />
      ))}
      <Image
        width={sortedImages[0].fields.file.details.image?.width}
        height={sortedImages[0].fields.file.details.image?.height}
        loading={isLazy ? 'lazy' : 'eager'}
        src={`${getContentfulImage(images[0], { q: imageCompression || 75, fm: imageFormat || 'WEBP' }) ?? ''}`}
        alt={sortedImages[0].fields.description ?? name ?? 'Incarail'}
      />
    </picture>
  );
}