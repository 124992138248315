import { GetServerSidePropsContext } from 'next';
import buildClient from '../../pages/api/build-client';

export async function getSchedules(
  locale: string,
  weekDay: string | null,
  context?: GetServerSidePropsContext) {

  const week = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    // Connect to API and retrieve content
  const client = buildClient(context ?? undefined);

  const { data } = await client.get(
    `/api/sir/schedule/${locale}${weekDay ? `/${week.indexOf(weekDay)}` : ''}`
  ).then((response) => {
    return response;
  }).catch((err) => {
    return err.response;
  });

  return data || null;
}

