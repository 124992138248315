/**
 * Returns the url of a contentful image field or fallbacks to null.
 */
export const getContentfulImage = (field: any, options?: any) => {
  const imageUrl = field?.fields?.file?.url || field?.url || null;

  // No image, return null.
  if (!imageUrl) return null;

  // If we get options, pass them as querystring, otherwise use the default optimization.
  const urlOptions = {
    q: 75,
    ...(typeof options === 'object' ? options : undefined),
  };

  const params = new URLSearchParams(urlOptions);
  return `https:${imageUrl}?${params.toString()}`;
};