import { SelectPromotionData } from "@/interfaces";

export const saveOrUpdatePromoDataToStorage = (data: SelectPromotionData) => {
  const now = new Date().getTime();
  const promotionTracked = {
      value: data,
      createdAt: now
  };
  const cookieValue = encodeURIComponent(JSON.stringify(promotionTracked));
  const expires = new Date(now + 7 * 24 * 60 * 60 * 1000);
  const cookieString = `lastPromotion=${cookieValue}; expires=${expires.toUTCString()}; path=/; domain=.incarail.com; Secure; SameSite=Lax`;

  document.cookie = cookieString;
};
