import { GetServerSidePropsContext } from 'next';
import buildClient from '../../pages/api/build-client';

export async function getTravelPackageCategories(
  locale: string,
  onlyGroupIds: string,
  context?: GetServerSidePropsContext) {

  // Connect to API and retrieve content
  const client = buildClient(context ?? undefined);

  const { data } = await client.get(
    `/api/sir/travel-packages-categories/${locale}/${onlyGroupIds ?? ''}`
  ).then((response) => {
    return response;
  }).catch((err) => {
    return err.response;
  });

  return data || null;
}

