import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react';

interface BookerContextInterface {
  isActive: boolean;
  activateBooker: () => void;
  deactivateBooker: () => void;
}

const defaultState = {
  isActive: false,
  activateBooker: () => {},
  deactivateBooker: () => {}
};

const BookerContext = React.createContext<BookerContextInterface>(defaultState);

export const useBookerContext = () => useContext(BookerContext);

export const BookerProvider = ({ children }: { children: JSX.Element }) => {
  const [isActive, setIsActive] = useState(false);

  const activateBooker = useCallback(() => {
    setIsActive(true);
  }, []);

  const deactivateBooker = useCallback(() => {
    setIsActive(false);
  }, []);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    if (isActive) {
      timeoutId = setTimeout(() => {
        deactivateBooker();
      }, 15000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isActive, deactivateBooker]);

  const contextValues = useMemo(() => ({
    isActive,
    activateBooker,
    deactivateBooker
  }), [isActive, activateBooker, deactivateBooker]);

  return (
    <BookerContext.Provider value={contextValues}>
      {children}
    </BookerContext.Provider>
  );
};
