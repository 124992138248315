import React, { useCallback, useMemo, useState } from 'react';

interface ContentContextInterface {
  navigation: any;
  setNavigation: (navigation: any) => void;
  handleSetNavigation: (
    navigation: any,
  ) => void;
  country: string;
  setCountry: (country: string) => void;
}
const defaultState = {
  navigation: null,
  setNavigation: () => {},
  handleSetNavigation: () => {},
  country: '',
  setCountry: () => {},
};

const ContentContext =
  React.createContext<ContentContextInterface>(defaultState);

const ContentProvider = ({ children }: { children: JSX.Element }) => {
  const [country, setCountry] = useState<string>('');
  const [navigation, setNavigation] = useState<any>(
    () => {
      if (typeof window === 'undefined') {
        return null;
      }

      const data = window.sessionStorage.getItem('killa_navigation') ?? 'null';
      const currentNavigation = JSON.parse(data);

      return currentNavigation || null;
    },    
  );

  const handleSetNavigation = useCallback(
    (navigation: any) => {
      if (typeof window === 'undefined') {
        return null;
      }
      if (!navigation) {
        window.sessionStorage.removeItem('killa_navigation');
        setNavigation(null);
        return null;
      }

      window.sessionStorage.setItem(
        'killa_navigation',
        JSON.stringify(navigation),
      );
      setNavigation(navigation);
    },
    []
  );
  
  const contextValues = useMemo(() => ({ navigation, setNavigation, handleSetNavigation, country, setCountry }), [navigation, setNavigation, handleSetNavigation, country, setCountry]);

  return (
    <ContentContext.Provider value={contextValues}>
      {children}
    </ContentContext.Provider>
  );
};

export default ContentContext;

export { ContentProvider };
