import axios from 'axios';

export async function sendEmail(name: string, email: string, area: string, message: string, lang?: string) {

  var datos = 'nombre='+ name + '&email=' + email + '&area=' + area + '&mensaje=' + message + '&desPrefijoLenguaje=' + lang;

  const { data } = await axios.post(
    `/public/procesos/proceso.php`, {
      data: datos
    }
  ).then((response) => {
    return response;
  }).catch((err) => {
    return err.response;
  });

  return data || null;
}

