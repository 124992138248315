import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

interface AxiosInstanceConfig {
  req?: AxiosRequestConfig;
}

const buildClient = ({ req }: AxiosInstanceConfig = {}): AxiosInstance => {

  if (typeof window === 'undefined') {
    // we are on the server
    // request should be made to http://ingress-nginx...
    return axios.create({
      baseURL: process.env.KILLA_ENV === 'dev' ? 'http://ingress-nginx-controller.ingress-nginx.svc.cluster.local' : process.env.KILLA_URL,
      headers: req?.headers,
    });

  } else {
    // we are on the browser
    // requests can be made with a base url of ''
    return axios.create({
      baseURL: '',
    });
  }
};

export default buildClient;