import TagManager from 'react-gtm-module';
import { BasicErrorData, PromiseRejectionErrorData } from '@/interfaces';

export const trackError = (errorData: BasicErrorData | PromiseRejectionErrorData) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'ir.fe_error',
      ...errorData
    }
  });
};

interface ErrorTrackingConfig {
  trackUnhandledRejections?: boolean;
}

export const initializeErrorTracking = ({ trackUnhandledRejections = true }: ErrorTrackingConfig = {}) => {
  window.addEventListener('error', (event: ErrorEvent) => {
    const errorData: BasicErrorData = {
      errorMessage: event.message,
      errorUrl: event.filename,
      errorLine: event.lineno,
      errorColumn: event.colno,
      errorStack: event.error ? event.error.stack : 'Not available'
    };

    trackError(errorData);
  });

  if (trackUnhandledRejections) {
    window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
      const errorData: PromiseRejectionErrorData = {
        errorMessage: `Unhandled promise rejection: ${event.reason ? (event.reason as Error).message : 'Unknown reason'}`,
        errorStack: (event.reason instanceof Error && event.reason.stack) ? event.reason.stack : 'Stack trace not available'
      };

      trackError(errorData);
    });
  }
};
